import { Link, useLocation } from "react-router-dom";
import "./App.css";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Sign from "./assets/Sign.svg";
import Aerrow from "./assets/Aerrow.svg";
import "swiper/css";
import "swiper/css/pagination";
import { scrollToTop } from "./App";
import { MetaTags } from "react-meta-tags";
function CaseStudiesDetail() {
  const [CaseStudiesData, setCaseStudiesData] = useState(null);
  const location = useLocation();
  // const data = location.state?.someData;
  // setCaseStudiesData([data])

  useEffect(() => {
    scrollToTop();
    var loader = document.getElementsByClassName("preloader")[0];
    loader.className = "preloader fadeout";
    loader.style.display = "flex";
  }, [location]);
  const assetsPAth = "../assets";
  useEffect(() => {
    window.onload = () => {
      var loader = document.getElementsByClassName("preloader")[0];
      loader.className = "preloader fadeout";
      loader.style.display = "none";
    };
  }, [location]);
  const [BlogData, setBlogData] = useState([]);
  const callBlog = async () => {
    try {
      const response = await fetch("https://arccusinc.com/casestudies.json", {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const pathname = location.pathname;
      console.log(
        "-------------------------------------------------",
        data.data
      );
      data?.data?.map((itemcs) => {
        console.log(
          "-------------------------------------------------",
          itemcs?.caseStudies
        );
        if (pathname.includes(itemcs?.caseStudies.caseStudiesLink)) {
          setCaseStudiesData(itemcs?.caseStudies);
        }
      });
      setTimeout(() => {
        var loader = document.getElementsByClassName("preloader")[0];
        loader.className = "preloader fadeout";
        loader.style.display = "none";
      }, 1000);
      setBlogData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    callBlog();
  }, [location]);

  return (
    <div className="App">
      <MetaTags>
        <meta name="description" content={CaseStudiesData?.metaDisc} />
        <meta property="og:title" content={CaseStudiesData?.metaTitle} />
        <title>{CaseStudiesData?.metaTitle}</title>
        <link rel="canonical" href={CaseStudiesData?.metalink} />
      </MetaTags>
      <div className="casestudies-home technology-home">
        <div className="casestudies-home section-padding">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-5">
                <h6>{CaseStudiesData?.ProjectName}</h6>
                <h1>{CaseStudiesData?.Title}</h1>
                <p>{CaseStudiesData?.Discription}</p>
              </div>
              <div className="col-md-6">
                <img
                  className="w-100"
                  src={`${assetsPAth}${CaseStudiesData?.coverImg.url}`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section-padding the-chellange">
          <div className="container">
            <h1 className="text-center mx-auto">
              {CaseStudiesData?.challage.title}
            </h1>
            <h3 className="text-center">
              {CaseStudiesData?.challage.subTitle}
            </h3>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-4">
                <img
                  src={`${assetsPAth}${CaseStudiesData?.challage?.Image?.url}`}
                />
              </div>
              <div className="col-md-6">
                <p>{CaseStudiesData?.challage.Discription}</p>
                <div className="challange-list">
                  {CaseStudiesData?.challage.challangeList.map(
                    (challangeList) => (
                      <div className="challange-item">
                        <img
                          src={`${assetsPAth}${challangeList.icon[0].url}`}
                        />
                        <label>{challangeList.text}</label>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-padding the-solution">
          <div className="container">
            <h1 className="text-center mx-auto">
              {CaseStudiesData?.Solution.heading}
            </h1>
            <h3 className="text-center">
              {CaseStudiesData?.Solution.SubHeading}
            </h3>
            <div className="text-center video-block">
              <img
                className="w-100 h-100 object-cover object-center"
                src={`${assetsPAth}${CaseStudiesData?.Solution.File.url}`}
              />
            </div>
          </div>
        </div>

        <div className="section-padding the-result">
          <div className="container">
            <h1 className="text-center mx-auto">
              {CaseStudiesData?.Results.Heading}
            </h1>
            <h3 className="text-center">
              {CaseStudiesData?.Results.SubHeading}
            </h3>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-5">
                <ReactMarkdown
                  children={CaseStudiesData?.Results.Discription}
                />
                <div className="review-card text-center">
                  <img className="mb-2" src={Sign} />
                  <p>{CaseStudiesData?.Results.Review}</p>
                  <h6>{CaseStudiesData?.Results.ReviewBottom}</h6>
                </div>
              </div>
              <div className="col-md-5">
                <img
                  src={`${assetsPAth}${CaseStudiesData?.Results.Image.url}`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="section-padding the-help">
          <div className="container">
            <h1 className="text-center mx-auto">
              {CaseStudiesData?.helped.title}
            </h1>
            <h3 className="text-center">{CaseStudiesData?.helped.SubTitle}</h3>
            <p className="text-center">{CaseStudiesData?.helped.Discription}</p>
            <div className="row justify-content-center align-items-center">
              <div className="col-md-4">
                <img
                  src={`${assetsPAth}${CaseStudiesData?.helped.image.url}`}
                />
              </div>
              <div className="col-md-6">
                <div className="helped-shape">
                  {CaseStudiesData?.helped.List.map((item, index) => (
                    <div className="halped-shape-item">
                      {/* <img src={`${assetsPAth}${item.icon.url}`} /> */}
                      <div className="index">
                        {index + 1} <img src={Aerrow} />
                      </div>
                      <h4>{item.discription}</h4>
                      {/* <p>{item.discription}</p> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-padding thechnology-behind">
          <div className="container">
            <h1 className="text-center mx-auto">
              {CaseStudiesData?.behindTech.title}
            </h1>
            <h3 className="text-center">
              {CaseStudiesData?.behindTech.subtitle}
            </h3>
            <p className="text-center">
              {CaseStudiesData?.behindTech.discription}
            </p>
            <div className="technology-behind">
              {CaseStudiesData?.behindTech.list.map((item) => (
                <div className="technology-behind-item">
                  <img src={`${assetsPAth}${item.icon.url}`} />
                  <h6>{item.title}</h6>
                  <p>{item.discription}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="section-padding more-success-story">
          <div className="container">
            <h1 className="text-center mx-auto">More Success Stories</h1>
            <div className="technical-support row justify-content-center">
              <div className="client-slider-tech position-relative pb-5 col-md-10">
                <Swiper
                  modules={[Pagination]}
                  slidesPerView={2}
                  spaceBetween={16}
                  pagination={true}
                  className="mySwiper"
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    991: {
                      slidesPerView: 2,
                    },
                  }}
                >
                  {BlogData?.map((item) => {
                    return (
                      <SwiperSlide>
                        <div>
                          <img
                            src={`${assetsPAth}${item.caseStudies.coverImg.url}`}
                          />
                          <h4>{item.caseStudies.ProjectName}</h4>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>{item.caseStudies.Title}</p>
                            <Link
                              to={
                                "/case-studies/" +
                                item.caseStudies.caseStudiesLink
                              }
                            >
                              {item.caseStudies.btntitle}
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudiesDetail;
