import { Link } from "react-router-dom";
import "./App.css";
import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import blog from "./blog.json"
import "react-tabs/style/react-tabs.css";
import MetaTags from "react-meta-tags";
import { scrollToTop } from "./App";
import Bgbanner from "./assets/technology-banner-bg.svg";
function CaseStudie() {
  useEffect(() => {
    scrollToTop();
  }, []);
  const assetsPAth = "../assets"
  const [Category, setCategory] = useState([]);
  const [active, setActive] = useState("");
  const [BlogData, setBlogData] = useState([]);
  const callCategory = async () => {
    try {
      const response = await fetch("https://arccusinc.com/casestudiescategory.json", {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setCategory(data.data);
      setActive(data.data[0].slug);
      callBlog(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const callBlog = async (categories) => {
    try {
      const response = await fetch(
        "https://arccusinc.com/casestudies.json",
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      let blog = [];
      categories.map((cate, ind) => {
        // let casse = null
        let caseStudies = [];
        data.data.map((item) => {
          if (ind === 0) {
            caseStudies.push(item);
          } else {
            item.caseStudies.case_studies_categories.map((ct) => {
              if (cate.id == ct.id) {
                caseStudies.push(item);
              }
            });
          }
        });
        blog.push(caseStudies);
      });
      // const uniqueItems = blog.filter((value, index, self) =>
      //   index === self.findIndex((t) => (
      //     t.id === value.id
      //   ))
      // );
      setBlogData(blog);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    callCategory();
  }, []);

  const RenderItem = ({ blog }) => {
    {
      console.log("---------------", blog);
    }
    return (
      <div className="casestudies-item row justify-content-center gap-0">
        <div
          className="col-md-6"
          style={{ background: `${blog.caseStudies.bgcolor}` }}
        >
          <div className="casestudies-cover">
            <img
              src={`${assetsPAth}${blog.caseStudies.coverImg.url}`}
              className=""
            />
          </div>
        </div>
        <div className="col-md-5 casestudies-main-content">
          <div>
            <h5>{blog.caseStudies.ProjectName}</h5>
            <h3>{blog.caseStudies.Title}</h3>
          </div>
          <div>
            <div className="casestudies-technology">
              {blog.caseStudies.Technology.map((tech) => (
                <img src={`${assetsPAth}${tech.tech.url}`} />
              ))}
            </div>
            <Link
              to={"/case-studies/" + blog.caseStudies.caseStudiesLink}
              state={{ someData: blog.caseStudies }}
            >
              {blog.caseStudies.btntitle}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="App Blog">
      <MetaTags>
        <meta
          name="description"
          content="Dive into the Arccus blog for insights on digital engineering, web tech, app development, and more. Stay updated with the latest trends and strategies!"
        />
        <meta name="keyword" content="Web & Mobile Technology Blog" />
        <meta
          property="og:title"
          content="Web & Mobile Technology Blog, Latest Technology Updates - Arccus"
        />
        <link rel="canonical" href="https://arccusinc.com/blog" />
        <title>Explore Digital Trends & Tech Insights | Arccus Inc Blog</title>
      </MetaTags>
      <section className="blog case-banner position-relative">
        <div className="container">
          <h3 className="text-center case-title">
            Want to See Our Success Stories?
          </h3>
          <h1 className="text-center mb-4">Check Out Our Case Studies</h1>
        </div>
        <div className="position-absolute w-100 h-100 top-0 left-0 technology-bg overflow-hidden">
          <img src={Bgbanner} />
        </div>
      </section>

      <section className="blog-tiles">
        <div className="container">
          <Tabs>
            <TabList>
              {Category.map((link) => (
                <Tab
                  href={`#${link.slug}`}
                  className={` ${active === link.slug && "m-active"}`}
                  onClick={() => setActive(link.slug)}
                >
                  {link.category}
                </Tab>
              ))}
            </TabList>

            {BlogData?.map((caseStudies, ind) => {
              return (
                <TabPanel>
                  <div className="casestudies-main">
                    {caseStudies.length > 0 ? (
                      caseStudies?.map((b) => {
                        return <RenderItem blog={b} />;
                      })
                    ) : (
                      <div>No Data Found</div>
                    )}
                  </div>
                </TabPanel>
              );
            })}
          </Tabs>
        </div>
      </section>
    </div>
  );
}

export default CaseStudie;
