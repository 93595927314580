import "./App.css";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { scrollToTop } from "./App";
import Bgbanner from "./assets/technology-banner-bg.svg";
import Checkbox from "./assets/TechnologyCheckbox.svg";
import logobg from "./assets/logobg.svg";
// import logobg from "./assets/logobg.svg";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import StarRatings from "react-star-ratings";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link, useLocation } from "react-router-dom";
import Parser from "html-react-parser";
import Marquee from "react-fast-marquee";

function TechnologyHome() {
  const location = useLocation();

  useEffect(() => {
    scrollToTop();
  }, []);
  const assetsPAth = "../assets";
  const pagination1 = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "0" + (index + 1) + "</span>";
    },
  };
  const [technologyData, settechnologyData] = useState([]);

  useEffect(() => {
    const data = location.state?.someData;

    settechnologyData([data]);
    window.onload = () => {
      console.log("-------------------------------------------------");
      var loader = document.getElementsByClassName("preloader")[0];
      loader.className = "preloader fadeout";
      loader.style.display = "none";
    };
  }, [location]);

  const callApiTechnology = async () => {
    try {
      const response = await fetch("https://arccusinc.com/data.json", {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const path = window.location.pathname;
      const dd = data.data.filter((item) =>
        path.replaceAll("#", "").includes(item.pagelink)
      );
      settechnologyData([dd[0]]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    callApiTechnology();
  }, [location, window.location.pathname]);
  return (
    <div className="technology-home">
      {technologyData.map((technology) => (
        <div>
          <MetaTags>
            <meta name="description" content={technology?.metaDisc} />
            <meta name="keyword" content={technology?.keyword} />
            <meta property="og:title" content={technology?.metaTitle} />
            <link rel="canonical" href={technology?.metaLink} />
            <title>{technology?.metaTitle}</title>
          </MetaTags>
          <div className="technology-hero position-relative">
            <div className="position-absolute w-100 h-100 top-0 left-0 technology-bg overflow-hidden">
              <img src={Bgbanner} />
            </div>
            <div className="hero-flex container">
              <div className="hero-left">
                <h1>{technology?.MainHead}</h1>
                <p>{technology?.SubText}</p>
              </div>
              <div className="hero-right">
                <form>
                  <h3>Talk to us</h3>
                  <input placeholder="Name" />
                  <input placeholder="Email" />
                  <input placeholder="Number" />
                  <textarea placeholder="Be yourself everyone else is already taken" />
                  <div className="accept-terms">
                    <input type="checkbox" />
                    <p>
                      I accept the Privacy Policy and agree to process my
                      personal data by Pagepro for marketing purposes.
                    </p>
                  </div>
                  <div className="text-center">
                    <Link to="/contact-us" target="_blank">Let’s Talk</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="section-padding">
            <div className="container">
              <h2 className="text-center mx-auto">
                {technology?.about.Heading}
              </h2>
              <h6>{technology?.about.SubText}</h6>
              <h2 className="text-center mx-auto">{technology?.benifit.heading}</h2>
              <div className="benifit-grid">
                {technology?.benifit.benifitsdata.map((item) => (
                  <div className="benifit-grid-item">
                    <div className="technology-checkbox">
                      <img src={Checkbox} />
                    </div>
                    <div className="benifit-text">
                      <h3>{item.title}</h3>
                      <p>{item.detail}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <section id="tech-stack" className="technology section-padding">
            <div className="marquee d-block">
              <div className="container">
                <h2 className="text-center mx-auto">
                  {technology?.technologyWeUse.heading}
                </h2>
              </div>
              <Marquee gradient={false} pauseOnHover={true} speed={75}>
                {technology?.technologyWeUse?.technology.map((technology, ind) => {
                  return (
                    <div className="technology-slide ">
                      <div className="techs-head">
                        <h5>{technology?.name}</h5>
                      </div>
                      <div className="technology-row">
                        {technology?.tech?.map((item) => {
                          return (
                            <div className="technology-pan">
                              <img
                                src={`${assetsPAth}${item.icon.url}`}
                                alt={"technology"}
                                loading="lazy"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </Marquee>
            </div>
          </section>
          <div className="section-padding">
            <div className="container">
              <div className="business-verticalx">
                <div className="row">
                  <div className="col-md-1" />
                  <div className="col-md-4">
                    <h2>{technology?.bussinessVertical.Heading}</h2>
                    <p>{technology?.bussinessVertical?.SubText}</p>
                  </div>
                  <div className="col-md-1" />
                  <div className="col-md-5">
                    <div className="verticalx-grid">
                      {technology?.bussinessVertical.BussinessCategory.map(
                        (item) => (
                          <div className="verticalx-item">
                            <img src={`${assetsPAth}${item.Icon.url}`} />
                            <label>{item.name}</label>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="col-md-1" />
                </div>
              </div>
            </div>
          </div>
          <div
            id="our-processes"
            className="section-padding development-process"
          >
            <div className="container">
              <h2 className="text-center mx-auto mw-100">
                {technology?.processDevelopmet.heading}
              </h2>
              <h6>{technology?.processDevelopmet.SubHeading}</h6>
              <div className="client-slider-tech position-relative pb-5">
                <Swiper
                  modules={[Pagination]}
                  slidesPerView={4}
                  spaceBetween={40}
                  pagination={{ clickable: true }}
                  className="mySwiper"
                  breakpoints={{
                    0: {
                      slidesPerView: 2,
                      spaceBetween: 16,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    991: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {technology?.processDevelopmet.processBlock.map(
                    (item, index) => {
                      return (
                        <SwiperSlide>
                          <div className="slide_number">{index + 1}</div>
                          <div className="development-slide">
                            <img src={`${assetsPAth}${item.icon.url}`} />
                            <h4>{item.title}</h4>
                            <p>{item.detail}</p>
                          </div>
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
              </div>
            </div>
          </div>
          <div id="services" className="section-padding technical-support">
            <div className="container">
              <h2 className="text-center mx-auto pb-0">{technology?.Service.mainText}</h2>
              <h5 className="text-center mx-auto mw-100 pb-4">
                {technology?.Service.Heading}
              </h5>
              <div className="client-slider-tech position-relative pb-5">
                <Swiper
                  modules={[Pagination]}
                  slidesPerView={3}
                  spaceBetween={16}
                  pagination={{ clickable: true }}
                  className="mySwiper"
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    991: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {technology?.Service.serviceBlock.map((item) => {
                    return (
                      <SwiperSlide>
                        <div>
                          <img src={`${assetsPAth}${item?.icon?.url}`} />
                          <h4>{item.title}</h4>
                          <p>{item.discription}</p>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>

          <div id="case-studies" className="section-padding">
            <div className="case-study-block position-relative">
              <div className="position-absolute w-100 h-100 top-0 left-0 technology-bg overflow-hidden">
                <img src={Bgbanner} />
              </div>
              <div className="container">
                <h2 className="text-center mx-auto">Case Studies</h2>
                <div className="client-slider-tech">
                  <Swiper
                    pagination={pagination1}
                    modules={[Navigation, Pagination]}
                    slidesPerView={1}
                    navigation={true}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            className="casestudies-project"
                            style={{ background: "#E8E2FC" }}
                          >
                            <img src="../assets/uploads/Group_61077_0f5b575ac4.svg" />
                          </div>
                        </div>
                        <div className="col-md-5 casestudies-content">
                          <div>
                            <h5>Reeva</h5>
                            <h3>
                              AI-Powered Assistant Revolutionizing Real Estate
                              Efficiency
                            </h3>
                            <p>
                              Developed by Arccus Inc., Reeva empowers real
                              estate professionals in Texas to streamline
                              operations and save valuable time through
                              AI-driven tools.
                            </p>
                          </div>
                          <div>
                            <div className="technology-use">
                              <img
                                src="../assets/uploads/download_f6a762b2fe.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_ef229124af.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_01b79a7cec.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_768b2576d0.svg"
                                alt=""
                              />
                            </div>
                            <Link to="/case-studies" target="_blank">Read Case Study</Link>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            className="casestudies-project"
                            style={{ background: "#E8E2FC" }}
                          >
                            <img src="../assets/uploads/Group_1000002512_caf521ce0f_bb0374e274.svg" />
                          </div>
                        </div>
                        <div className="col-md-5 casestudies-content">
                          <div>
                            <h5>Rever.faith</h5>
                            <h3>
                              Empowering Spiritual Communicators with
                              Rever.faith
                            </h3>
                            <p>
                              Developed by Arccus Inc., Reeva empowers real
                              estate professionals in Texas to streamline
                              operations and save valuable time through
                              AI-driven tools.
                            </p>
                          </div>
                          <div>
                            <div className="technology-use">
                              <img
                                src="../assets/uploads/download_f6a762b2fe.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_ef229124af.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_01b79a7cec.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_768b2576d0.svg"
                                alt=""
                              />
                            </div>
                            <Link to="/case-studies" target="_blank">Read Case Study</Link>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            className="casestudies-project"
                            style={{ background: "#E8E2FC" }}
                          >
                            <img src="../assets/uploads/Group_61077_1_e5b7efeff7_090eb0ac4e.svg" />
                          </div>
                        </div>
                        <div className="col-md-5 casestudies-content">
                          <div>
                            <h5>GBN Dashboard</h5>
                            <h3>
                              Revolutionizing Business Operations with the GBN
                              Dashboard
                            </h3>
                            <p>
                              Developed by Arccus Inc., Reeva empowers real
                              estate professionals in Texas to streamline
                              operations and save valuable time through
                              AI-driven tools.
                            </p>
                          </div>
                          <div>
                            <div className="technology-use">
                              <img
                                src="../assets/uploads/download_f6a762b2fe.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_ef229124af.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_01b79a7cec.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_768b2576d0.svg"
                                alt=""
                              />
                            </div>
                            <Link to="/case-studies" target="_blank">Read Case Study</Link>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            className="casestudies-project"
                            style={{ background: "#E8E2FC" }}
                          >
                            <img src="../assets/uploads/Group_1000002381_9260b30109_9a591a54ce.svg" />
                          </div>
                        </div>
                        <div className="col-md-5 casestudies-content">
                          <div>
                            <h5>Plesy Invoice</h5>
                            <h3>
                              Revolutionizing Small Business Finance Management
                              with Plesy Invoice
                            </h3>
                            <p>
                              Developed by Arccus Inc., Reeva empowers real
                              estate professionals in Texas to streamline
                              operations and save valuable time through
                              AI-driven tools.
                            </p>
                          </div>
                          <div>
                            <div className="technology-use">
                              <img
                                src="../assets/uploads/download_f6a762b2fe.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_ef229124af.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_01b79a7cec.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_768b2576d0.svg"
                                alt=""
                              />
                            </div>
                            <Link to="/case-studies" target="_blank">Read Case Study</Link>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            className="casestudies-project"
                            style={{ background: "#E8E2FC" }}
                          >
                            <img src="../assets/uploads/Group_1000002450_eb4490dd20_b22c739d42.svg" />
                          </div>
                        </div>
                        <div className="col-md-5 casestudies-content">
                          <div>
                            <h5>Speakclub</h5>
                            <h3>
                              Enhancing Communication Skills with Speakclub
                            </h3>
                            <p>
                              Developed by Arccus Inc., Reeva empowers real
                              estate professionals in Texas to streamline
                              operations and save valuable time through
                              AI-driven tools.
                            </p>
                          </div>
                          <div>
                            <div className="technology-use">
                              <img
                                src="../assets/uploads/download_f6a762b2fe.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_ef229124af.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_01b79a7cec.svg"
                                alt=""
                              />
                              <img
                                src="../assets/uploads/download_768b2576d0.svg"
                                alt=""
                              />
                            </div>
                            <Link to="/case-studies" target="_blank">Read Case Study</Link>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>

          <div className="section-padding">
            <div className="container">
              <h2 className="text-center mx-auto">Review From Clutch</h2>
              <div className="client-exp position-relative row">
                <div className="col-md-1" />
                <div className="client-slider-tech col-md-10">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    slidesPerView={1}
                    navigation={true}
                    pagination={{ clickable: true }}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="row">
                        <div className="col-md-5">
                          <h5>
                            "They are very much aligned with the values that we
                            are seeking."
                          </h5>
                          <Link to="/contact-us" target="_blank">Let's work together</Link>
                        </div>
                        <div className="col-md-1" />
                        <div className="col-md-6">
                          <img
                            className="client-logo"
                            src="../assets/uploads/download_4a97f24a58.png"
                          />
                          <p className="review-text">
                            “Arccus Inc. completed the required platforms,
                            meeting expectations. The web app they delivered
                            reduced face-to-face interactions with students. The
                            team completed tasks in a timely manner and was
                            responsive to feedback. Moreover, their company
                            values aligned with that of the client.”
                          </p>
                          <div className="client-detail">
                            <div className="client-img">
                              <img src="../assets/uploads/download_3c93710288.png" />
                            </div>
                            <div className="client-name-des">
                              <p>Ankit Solanki</p>
                              <span>CEO at Innovate Solutions</span>
                            </div>
                          </div>
                          <div className="review-from">
                            <div className="review-count">
                              4.7
                              <div className="review-star">
                                <StarRatings
                                  rating={Number(4.7)}
                                  starRatedColor="#FCD503"
                                  starEmptyColor="#6A6A6A"
                                  numberOfStars={5}
                                  starDimension="12px"
                                  starSpacing="1px"
                                  name="rating"
                                />
                              </div>
                            </div>
                            <div className="review-on">
                              Reviewed on
                              <img src="../assets/uploads/download_3917a9644c.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row">
                        <div className="col-md-5">
                          <h5>
                            "They gave us a professional site that's easy to
                            update."
                          </h5>
                          <Link to="/contact-us" target="_blank">Let's work together</Link>
                        </div>
                        <div className="col-md-1" />
                        <div className="col-md-6">
                          <img
                            className="client-logo"
                            src="../assets/uploads/download_4a97f24a58.png"
                          />
                          <p className="review-text">
                            The company achieved a 75% increase in traffic, and
                            their leads doubled month over month. Arccus Inc.
                            managed the project well, communicating closely with
                            the client and informing them of timelines, delays,
                            and issues. They used Slack and Trello to facilitate
                            a smooth collaboration between teams.
                          </p>
                          <div className="client-detail">
                            <div className="client-img">
                              <img src="../assets/uploads/download_3c93710288.png" />
                            </div>
                            <div className="client-name-des">
                              <p>Pritesh Jobanputra</p>
                              <span>CEO at Innovate Solutions</span>
                            </div>
                          </div>
                          <div className="review-from">
                            <div className="review-count">
                              4.7
                              <div className="review-star">
                                <StarRatings
                                  rating={Number(4.7)}
                                  starRatedColor="#FCD503"
                                  starEmptyColor="#6A6A6A"
                                  numberOfStars={5}
                                  starDimension="12px"
                                  starSpacing="1px"
                                  name="rating"
                                />
                              </div>
                            </div>
                            <div className="review-on">
                              Reviewed on
                              <img src="../assets/uploads/download_3917a9644c.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row">
                        <div className="col-md-5">
                          <h5>"Arccus Inc. was very easy to work with."</h5>
                          <Link to="/contact-us" target="_blank">Let's work together</Link>
                        </div>
                        <div className="col-md-1" />
                        <div className="col-md-6">
                          <img
                            className="client-logo"
                            src="../assets/uploads/download_4a97f24a58.png"
                          />
                          <p className="review-text">
                            Arccus Inc. successfully completed the project on
                            time. The team proved to be very easy to work with
                            and responded promptly every time, maintaining
                            communication with the client through virtual
                            meetings.
                          </p>
                          <div className="client-detail">
                            <div className="client-img">
                              <img src="../assets/uploads/download_3c93710288.png" />
                            </div>
                            <div className="client-name-des">
                              <p>Faith Frederick</p>
                              <span>CEO at Innovate Solutions</span>
                            </div>
                          </div>
                          <div className="review-from">
                            <div className="review-count">
                              4.7
                              <div className="review-star">
                                <StarRatings
                                  rating={Number(4.7)}
                                  starRatedColor="#FCD503"
                                  starEmptyColor="#6A6A6A"
                                  numberOfStars={5}
                                  starDimension="12px"
                                  starSpacing="1px"
                                  name="rating"
                                />
                              </div>
                            </div>
                            <div className="review-on">
                              Reviewed on
                              <img src="../assets/uploads/download_3917a9644c.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row">
                        <div className="col-md-5">
                          <h5>
                            "The project management was excellent, and
                            communication was always timely."
                          </h5>
                          <Link to="/contact-us" target="_blank">Let's work together</Link>
                        </div>
                        <div className="col-md-1" />
                        <div className="col-md-6">
                          <img
                            className="client-logo"
                            src="../assets/uploads/download_4a97f24a58.png"
                          />
                          <p className="review-text">
                            The MVP was completed based on the scope that was
                            outlined. Arccus Inc. was highly accessible
                            throughout the project, and internal stakeholders
                            were particularly impressed with Arccus Inc.'s work
                            ethic and team dynamic.
                          </p>
                          <div className="client-detail">
                            <div className="client-img">
                              <img src="../assets/uploads/download_3c93710288.png" />
                            </div>
                            <div className="client-name-des">
                              <p>Jared Carlin</p>
                              <span>CEO at Innovate Solutions</span>
                            </div>
                          </div>
                          <div className="review-from">
                            <div className="review-count">
                              4.7
                              <div className="review-star">
                                <StarRatings
                                  rating={Number(4.7)}
                                  starRatedColor="#FCD503"
                                  starEmptyColor="#6A6A6A"
                                  numberOfStars={5}
                                  starDimension="12px"
                                  starSpacing="1px"
                                  name="rating"
                                />
                              </div>
                            </div>
                            <div className="review-on">
                              Reviewed on
                              <img src="../assets/uploads/download_3917a9644c.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="col-md-1" />
              </div>
            </div>
          </div>

          <div className="section-padding">
            <div className="shadual-talk position-relative">
              <div className="position-absolute w-100 h-100 top-0 left-0 technology-bg overflow-hidden">
                <img src={Bgbanner} />
              </div>
              <div className="container">
                <div className="inner-content">
                  <div className="hero-right">
                    <div className="top-content">
                      <h4>Meet our expert</h4>
                      <h5>Let's schedule a 20-minute talk</h5>
                    </div>
                    <form>
                      <h3>Talk to us</h3>
                      <input placeholder="Name" />
                      <input placeholder="Email" />
                      <input placeholder="Number" />
                      <textarea placeholder="Be yourself everyone else is already taken" />
                      <div className="accept-terms">
                        <input type="checkbox" />
                        <p>
                          I accept the Privacy Policy and agree to process my
                          personal data by Pagepro for marketing purposes.
                        </p>
                      </div>
                      <div className="text-center">
                        <Link to="/contact-us" target="_blank">Let’s Talk</Link>
                      </div>
                    </form>
                  </div>
                  <div className="inner-right">
                    <img className="logo-bg" src={logobg} />
                    <div>
                      <img src="../assets/uploads/sdaf1_52e331866786af46681573a8da41dfc6_ce72a33167.svg" />
                    </div>
                    <div className="inner-sub-content">
                      <h5>Hi,</h5>
                      <p>
                        Welcome to the initial scoping call! The goal of this
                        call is to gain a deeper understanding of your project
                        requirements. You will have a clearer idea of your
                        project, along with a general estimate of time and cost.
                        We're excited to help bring your vision to life!
                      </p>
                      <h3>Ravi Kapupara</h3>
                      <span>CTO @ Arccus Inc.</span>

                      <a href="mailto:hello@arccusinc.com">
                        hello@arccusinc.com
                      </a>
                      <div className="connection-link">
                        <a
                          href="https://www.linkedin.com/company/arccus-inc/"
                          target="_blank"
                        >
                          <img src="../assets/uploads/linkedin_1ff339d5446f5a39be448c0faef3a7b5_99dfd6f919.svg" />
                        </a>
                        <a href="https://x.com/ArccusInc" target="_blank">
                          <img src="../assets/uploads/x_37456d567c3ce6a6094c5fc8f88eeead_5dc0c33fd3.svg" />
                        </a>
                        <a></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="faq" className="section-padding faq-tech">
            <div className="container">
              <h2 className="text-center mx-auto">{technology?.faq.heading}</h2>
              <div className="row">
                <div className="col-md-1" />
                <div className="faq-block d-block col-md-10">
                  <MDBAccordion initialActive={1}>
                    {technology?.faq.faqData.map((faqData, index) => (
                      <MDBAccordionItem
                        collapseId={index + 1}
                        headerTitle={faqData?.Question}
                      >
                        {Parser(faqData?.Answer)}
                      </MDBAccordionItem>
                    ))}
                  </MDBAccordion>
                </div>
                <div className="col-md-1" />
              </div>
            </div>
          </div>

          <div className="section-padding pb-0">
            <div className="other-service">
              <div className="container">
                <h5>{technology?.support.title}</h5>
                <h2 className="text-center mx-auto mw-100">
                  {technology?.support.heading}
                </h2>
                <div className="client-slider-tech bg-transparent shadow-0 position-relative pb-5">
                  <Swiper
                    pagination={true}
                    modules={[Pagination]}
                    slidesPerView={3}
                    spaceBetween={20}
                    className="other-service-list"
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {technology?.support.Support?.map((item) => {
                      return (
                        <SwiperSlide className="p-0">
                          <div className="other-service-item">
                            <img src={`${assetsPAth}${item.icon.url}`} />
                            <h4>{item.title}</h4>
                            <p>{item.discription}</p>
                            <Link to={item.link} target="_blank">{item.button}</Link>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TechnologyHome;
