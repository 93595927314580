import React, { useEffect, useState } from "react";
import skypebutton from "./assets/skype_PNG.webp";
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBCollapse,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import logo from "./assets/logo.webp";
import webengineering from "./assets/web-engineering.webp";
import mobility from "./assets/mobility.webp";
import gamedeveloping from "./assets/game-developing.webp";
import digitalmarketing from "./assets/digital-marketing.webp";
import uiuxdevelopment from "./assets/uiux.webp";
import whatsapp from "./assets/whatsapp.png";
import { HiOutlineArrowRight } from "react-icons/hi";
import { BsChevronDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import arccuslogo from "./assets/arccus-logo.webp";
import { useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "./assets/header/web-enjineering.json";
import { scrollToId } from "./App";
export default function App() {
  const lottieOptions = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };
  const { pathname } = useLocation();

  const [showNavColor, setShowNavColor] = useState(false);
  const [curruntCta, setCurruntCta] = useState(0);
  const [showCta, setshowCta] = useState(false);
  const [showNavColorSecond, setShowNavColorSecond] = useState(false);
  const [showNavColorThird, setShowNavColorThird] = useState(false);

  if (window.location.pathname === "/ar-vr-development") {
    document.querySelector("body").classList.add("my-class");
    document.querySelector("html").classList.add("remove-smooth-scroll");
  } else {
    document.querySelector("body").classList.remove("my-class");
    document.querySelector("html").classList.remove("remove-smooth-scroll");
  }
  if (window.location.pathname === "/thankyou") {
    document.querySelector("body").classList.add("tahnk_you");
  } else {
    document.querySelector("body").classList.remove("tahnk_you");
  }
  if (window.location.pathname === "/termsandcondition") {
    document.querySelector("body").classList.add("external-link");
  } else if (window.location.pathname === "/privacypolicy") {
    document.querySelector("body").classList.add("external-link");
  } else {
    document.querySelector("body").classList.remove("external-link");
  }

  const [technologyData, settechnologyData] = useState([]);

  const callApiTechnology = async () => {
    try {
      const response = await fetch("https://arccusinc.com/data.json", {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const path = window.location.pathname;
      const dd = data.data.findIndex((item) => path.replaceAll('#', '').includes(item.pagelink));
      setshowCta(dd.length>0)
      setCurruntCta(dd);
      settechnologyData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    callApiTechnology();
  }, []);
  useEffect(() => {
    const path = window.location.pathname;
    const hy = technologyData.filter((item)=>path.includes(item.pagelink))
    setshowCta(hy.length>0)
  }, [technologyData, window.location.pathname]);



  return (
    <>
      <div className="preloader fadeout">
        <div className="loader">
          <img className="icon" src={arccuslogo} alt={"logo"} />
        </div>
      </div>
      <a className="back-to-top position-fixed">
        <div className="back-toop-tooltip">
          <span>Back To Top</span>
        </div>
        <div className="top-arrow"></div>
        <div className="top-line"></div>
      </a>
      <MDBNavbar expand="lg" dark sticky>
        <MDBContainer>
          <MDBNavbarBrand href="#" className="logo">
            <Link to="/">
              <img src={logo} width={"160"} height={"50"} alt={"logo"} />
            </Link>
          </MDBNavbarBrand>
          <MDBNavbarToggler
            type="button"
            data-target="#navbarColor02"
            aria-controls="navbarColor02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavColor(!showNavColor)}
            id="menu-icon"
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <MDBCollapse show={showNavColor} navbar>
            <MDBNavbarNav className="mb-0 mt-0 m-auto w-auto">
              <MDBNavbarItem>
                <MDBNavbarLink aria-current="page">
                  <Link to="/" className="NavLink">
                    Home
                  </Link>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem className="main-menu">
                <MDBNavbarLink>
                  Services <BsChevronDown className="carret" />
                </MDBNavbarLink>
                <div className="Submenu">
                  <div className="subpage">
                    <ul>
                      <Link to="web-development" className="NavLink">
                        <li id="web-engineering">
                          <a>
                            Web Development{" "}
                            <HiOutlineArrowRight className="right-aerrow" />
                          </a>

                          <p>Turn ideas into powerful digital products</p>
                        </li>
                      </Link>
                      <Link to="mobile-app-development" className="NavLink">
                        <li id="mobility">
                          <a>
                            Mobile App Development{" "}
                            <HiOutlineArrowRight className="right-aerrow" />
                          </a>
                          <p>
                            Build products that perform optimally in normal and
                            extreme load conditions
                          </p>
                        </li>
                      </Link>
                      <Link to="ar-vr-development" className="NavLink">
                        <li id="arvr-development">
                          <a>
                            AR VR Development{" "}
                            <HiOutlineArrowRight className="right-aerrow" />
                          </a>

                          <p>Turn ideas into powerful digital products</p>
                        </li>
                      </Link>
                    </ul>
                  </div>
                  <div className="subpagecontent">
                    <div className="subpagedetail web-engineerings active">
                      <p className="submenu-head">Web</p>
                      <p className="submenu-head">Development</p>
                      <p className="sub-content">
                        Bulid or scale a custom product fot millions of users
                      </p>
                      <div>
                        <Lottie
                          options={lottieOptions}
                          height={400}
                          width={300}
                        />
                      </div>
                    </div>
                    <div className="subpagedetail mobility">
                      <p className="submenu-head">Mobile App </p>
                      <p className="submenu-head"> Development </p>
                      <p className="sub-content">
                        Bulid or scale a custom product fot millions of users
                      </p>

                      <img src={mobility} alt={"mobility"} />
                    </div>
                    <div className="subpagedetail game-developing">
                      <p className="submenu-head">game</p>
                      <p className="submenu-head">developing</p>
                      <p className="sub-content">
                        Bulid or scale a custom product fot millions of users
                      </p>

                      <img src={gamedeveloping} alt={"gamedeveloping"} />
                    </div>
                    <div className="subpagedetail digital-marketing">
                      <p className="submenu-head">digital</p>
                      <p className="submenu-head">marketing</p>
                      <p className="sub-content">
                        Bulid or scale a custom product fot millions of users
                      </p>

                      <img src={digitalmarketing} alt={"digitalmarketing"} />
                    </div>
                    <div className="subpagedetail ui-ux">
                      <p className="submenu-head">ui/ux</p>
                      <p className="submenu-head">development</p>
                      <p className="sub-content">
                        Bulid or scale a custom product fot millions of users
                      </p>

                      <img src={uiuxdevelopment} alt={"uiux development"} />
                    </div>
                    <div className="subpagedetail arvr-development ">
                      <p className="submenu-head">AR VR</p>
                      <p className="submenu-head">Development</p>
                      <p className="sub-content">
                        Bulid or scale a custom product fot millions of users
                      </p>

                      <img src={webengineering} alt={"webengineering"} />
                    </div>
                  </div>
                </div>
              </MDBNavbarItem>
              <MDBNavbarItem className="main-menu">
                <MDBNavbarLink>
                  Technology <BsChevronDown className="carret" />
                </MDBNavbarLink>
                <div className="Submenu">
                  <div className="subpage">
                    <ul>
                      {technologyData.map((item, index) => {
                        return (
                          <Link
                            key={item.pageid}
                            to={"/technology/" + item.pagelink}
                            state={{ someData: item }}
                            onClick={() => setCurruntCta(index)}
                            className="NavLink"
                          >
                            <li>
                              <a>
                                {item.pagename}{" "}
                                <HiOutlineArrowRight className="right-aerrow" />
                              </a>
                            </li>
                          </Link>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="subpagecontent">
                    <div className="subpagedetail web-engineerings active">
                      <div>
                        <Lottie
                          options={lottieOptions}
                          height={400}
                          width={300}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="/blog" className="NavLink">
                  Blog
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <Link to="about-us" className="NavLink">
                    About Us
                  </Link>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <Link to="contact-us" className="NavLink">
                    Contact Us
                  </Link>
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink>
                  <Link to="career" className="NavLink">
                    Career
                  </Link>
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
            <Link to="contact-us" className="quote">
              Request A Quote
            </Link>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
      <span className="quote sidetab">
        <Link to="contact-us">Get Free Quote Now!</Link>
      </span>
      {showCta && (
        <div className="cta-buttons">
          <div className="container">
            <div className="d-flex justify-content-between justify-content-center cta-btn-row">
              <p className="mb-0">{technologyData?.[curruntCta]?.ctaTitle}</p>
              {technologyData?.[curruntCta]?.ctaButton.map((item) => (
                <a onClick={()=>{
                  scrollToId(item?.link)
                }}>{item?.Name}</a>
              ))}
            </div>
          </div>
        </div>
      )}
      {/* <a href="skype:kapupara16495?chat" target='_blank' className='fixed-skype-button' >
      <img src={ skypebutton } width={50} height={50} alt={"skypebutton"}   />
      </a>
      <a href="https://wa.me/+917567475647" target='_blank' className='fixed-whatsapp-button' >
      <img src={ whatsapp } width={50} height={50} alt={"whatsappbutton"}   />
      </a> */}
    </>
  );
}
